import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../lib/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { s3Upload } from "../lib/awsLib";
import "./Teams.css";

export default function Teams() {
  const file = useRef(null);
  const { id } = useParams();
  const nav = useNavigate();
  const [team, setTeam] = useState(null);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadTeam() {
      return API.get("teams", `/teams/${id}`);
    }

    async function onLoad() {
      try {
        const team = await loadTeam();
        const { content, attachment } = team;

        if (attachment) {
          team.attachmentURL = await Storage.vault.get(attachment);
        }

        setContent(content);
        setTeam(team);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return content.length > 0;
  }
  
  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }
  
  function handleFileChange(event) {
    file.current = event.target.files[0];
  }
  
  function saveTeam(team) {
    return API.put("teams", `/teams/${id}`, {
      body: team,
    });
  }
  
  async function handleSubmit(event) {
    let attachment;
  
    event.preventDefault();
  
    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
  
    setIsLoading(true);
  
    try {
      if (file.current) {
        attachment = await s3Upload(file.current);
      }
  
      await saveTeam({
        content,
        attachment: attachment || team.attachment,
      });
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteTeam() {
    return API.del("teams", `/teams/${id}`);
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this team?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteTeam();
      nav("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
    <div className="Teams">
      {team && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="content">
            <Form.Control
              as="textarea"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2" controlId="file">
            <Form.Label>Attachment</Form.Label>
            {team.attachment && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={team.attachmentURL}
                >
                  {formatFilename(team.attachment)}
                </a>
              </p>
            )}
            <Form.Control onChange={handleFileChange} type="file" />
          </Form.Group>
          <div className="d-grid">
            <LoaderButton
                block="true"
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Save
            </LoaderButton>
            <LoaderButton
                block="true"
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Delete
            </LoaderButton>
          </div>
        </Form>
      )}
    </div>
  );
}